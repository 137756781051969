<template>
  <div v-cloak>
    <transition appear appear-active-class="fade-enter-active" name="fade">
      <div v-if="apiLoaded || errorText">
        <div v-if="apiLoaded" class="wrap">
          <h1 v-if="config.id != null">
            <div>Редактирование<br> конфигурации id {{ config.id }}</div>
          </h1>
          <h1 v-else>
            <div>Создание конфигурации анкетирования
              <v-btn outlined @click="$refs['fileInput'].click()">загрузить файл конфигурации</v-btn>
              <input ref="fileInput" style="display: none" type="file" @change="loadJson()">
            </div>
          </h1>

          <div v-if="config.id != null" class="creation-date">Дата
            создания<span>{{ config.createdDate | dateFormat }}</span></div>

          Название анкеты
          <v-text-field v-model="config.anket.title" placeholder="Укажите название анкеты"></v-text-field>
          Дополнительное название анкеты
          <v-text-field v-model="config.anket.subTitle"
                        placeholder="Эта строка будет отображаться под заголовком"></v-text-field>
          Описание
          <v-textarea v-model="config.anket.description" placeholder="Дайте описание анкеты"></v-textarea>
          Дополнительные пояснения
          <v-textarea v-model="config.anket.subDescription"
                      placeholder="Этот текст будет отображаться под описанием анкеты"></v-textarea>
          <v-checkbox v-model="config.anket.anonymous"
                      label="Анонимная анкета" @change="noShowResultToggle(config.anket.anonymous)"></v-checkbox>
          <v-checkbox v-model="config.anket.noShowResult"
                      label="Не показывать результат после заполнения" :disabled="config.anket.anonymous"></v-checkbox>

          <div class="list config">


            <div v-for="(step, stepIndex) in config.anket.steps" :key="'level1sdfsaf'+stepIndex" class="list-item-wrap">

              <v-expansion-panels>
                <v-expansion-panel
                    :key="'level1sdfsasdddFaf'+stepIndex"
                >
                  <v-expansion-panel-header inside @keyup.space.prevent expand-icon="">

                    <div class="list-item">
                      <div class="list-item-name">
                        <button class="toggleSwitch">
                          <svg class="closed" fill="none" height="24" viewBox="0 0 24 24" width="24"
                               xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 14.5L17 9.5L7 9.5L12 14.5Z" fill="#1551D0"/>
                          </svg>
                          <svg class="opened" fill="none" height="24" viewBox="0 0 24 24" width="24"
                               xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.5 12L9.5 7L9.5 17L14.5 12Z" fill="#1551D0"/>
                          </svg>
                        </button>
                        <span><h4 v-if="stepIndex===0">Разделы анкеты</h4><q>{{ stepIndex + 1 }}</q>
                          <v-text-field
                              v-model="step.name" @click.stop=""></v-text-field></span>
                        <div v-if="config.anket.steps.length > 1"
                             class="delete"
                             @click.stop="deleteStep(stepIndex)">
                          <v-icon color="#0033A0">mdi-delete-outline</v-icon>
                        </div>
                      </div>
                    </div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>


                    <v-expansion-panels>
                      <v-expansion-panel
                          v-for="(question, questionIndex) in step.questions"
                          :key="'level2fiEif'+stepIndex + '.'+questionIndex"
                          class="level2">


                        <v-expansion-panel-header @keyup.space.prevent expand-icon="">
                          <div class="list-item">
                            <div class="list-item-name">
                              <button class="toggleSwitch whiteBg">
                                <svg class="closed" fill="none" height="24" viewBox="0 0 24 24" width="24"
                                     xmlns="http://www.w3.org/2000/svg">
                                  <path d="M12 14.5L17 9.5L7 9.5L12 14.5Z" fill="#1551D0"/>
                                </svg>
                                <svg class="opened" fill="none" height="24" viewBox="0 0 24 24" width="24"
                                     xmlns="http://www.w3.org/2000/svg">
                                  <path d="M14.5 12L9.5 7L9.5 17L14.5 12Z" fill="#1551D0"/>
                                </svg>
                              </button>
                              <span><h4 v-if="questionIndex===0">Вопросы</h4><q>{{ questionIndex + 1 }}.</q><v-text-field
                                  v-model="question.text" @click.stop=""></v-text-field></span>
                              <div class="delete" @click.stop="questionDown(step, questionIndex)">
                                <v-icon color="#0033A0">mdi-arrow-down-bold-outline</v-icon>
                              </div>
                              <div class="delete" @click.stop="questionUp(step, questionIndex)">
                                <v-icon color="#0033A0">mdi-arrow-up-bold-outline</v-icon>
                              </div>
                              <div class="delete" @click.stop="step.questions.splice(questionIndex, 1), $forceUpdate()">
                                <svg fill="none" height="24" viewBox="0 0 24 24" width="24"
                                     xmlns="http://www.w3.org/2000/svg">
                                  <path
                                      d="M6 19C6 19.5304 6.21071 20.0391 6.58579 20.4142C6.96086 20.7893 7.46957 21 8 21H16C16.5304 21 17.0391 20.7893 17.4142 20.4142C17.7893 20.0391 18 19.5304 18 19V7H6V19ZM8 9H16V19H8V9ZM15.5 4L14.5 3H9.5L8.5 4H5V6H19V4H15.5Z"
                                      fill="#0033A0"/>
                                </svg>
                              </div>
                            </div>
                          </div>
                        </v-expansion-panel-header>

                        <v-expansion-panel-content class="level3">
                          <div class="list-item-wrap">
                            <v-text-field v-model="question.partitionBreak"
                                          label="Название нового раздела вопросов"></v-text-field>
                            <v-text-field v-model="question.subText"
                                          label="Дополнительный текст вопроса"></v-text-field>
                            <v-text-field v-model="question.description" label="Пояснения к вопросу"></v-text-field>

                            <v-checkbox v-model="question.multiple"
                                        label="Допускается несколько вариантов ответов"></v-checkbox>


                            <v-checkbox v-model="question.typeTenScore"
                                        label="Скоринг (значения в ряд)"></v-checkbox>
                            <v-checkbox v-model="question.typeText"
                                        label="Текстовое поле (свободный ответ)"></v-checkbox>

                            <table class="config-table">
                              <thead>
                              <tr>
                                <th colspan="2">Варианты ответов</th>
                                <th colspan="2">Баллы за ответ</th>
                              </tr>
                              </thead>
                              <tbody>
                              <tr v-for="(option, optionIndex) in question.options"
                                  :key="'sadf'+stepIndex+'.'+questionIndex+'.'+optionIndex">
                                <td>{{ optionIndex + 1 }}.</td>
                                <td>
                                  <v-text-field v-model="option.text"></v-text-field>
                                </td>
                                <td>
                                  <v-text-field v-model="option.score"></v-text-field>
                                </td>
                                <td>
                                  <div v-if="question.options.length>2" class="delete"
                                       @click="question.options.splice(optionIndex,1), $forceUpdate()">
                                    <svg fill="none" height="24" viewBox="0 0 24 24" width="24"
                                         xmlns="http://www.w3.org/2000/svg">
                                      <path
                                          d="M6 19C6 19.5304 6.21071 20.0391 6.58579 20.4142C6.96086 20.7893 7.46957 21 8 21H16C16.5304 21 17.0391 20.7893 17.4142 20.4142C17.7893 20.0391 18 19.5304 18 19V7H6V19ZM8 9H16V19H8V9ZM15.5 4L14.5 3H9.5L8.5 4H5V6H19V4H15.5Z"
                                          fill="#0033A0"/>
                                    </svg>
                                  </div>
                                </td>
                              </tr>
                              </tbody>
                            </table>
                            <a class="configAdd" href="#" @click.prevent="addOption(question)">+ Добавить вариант
                              ответа</a>

                          </div>


                          <v-expansion-panels>
                            <v-expansion-panel
                                v-for="(subQuestion, subQuestionIndex) in question.subQuestions"
                                :key="'level3sfrEif'+stepIndex + '.'+questionIndex + '.' + subQuestionIndex"
                                class="level3">


                              <v-expansion-panel-header @keyup.space.prevent expand-icon="">
                                <div class="list-item">
                                  <div class="list-item-name">
                                    <button class="toggleSwitch whiteBg">
                                      <svg class="closed" fill="none" height="24" viewBox="0 0 24 24" width="24"
                                           xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12 14.5L17 9.5L7 9.5L12 14.5Z" fill="#1551D0"/>
                                      </svg>
                                      <svg class="opened" fill="none" height="24" viewBox="0 0 24 24" width="24"
                                           xmlns="http://www.w3.org/2000/svg">
                                        <path d="M14.5 12L9.5 7L9.5 17L14.5 12Z" fill="#1551D0"/>
                                      </svg>
                                    </button>
                                    <span><h4 v-if="subQuestionIndex===0">Подвопросы</h4><q>{{
                                        subQuestionIndex + 1
                                      }}.</q><v-text-field
                                        v-model="subQuestion.text" @click.stop=""></v-text-field></span>
                                    <div class="delete"
                                         @click.stop="question.subQuestions.splice(subQuestionIndex, 1), $forceUpdate()">
                                      <svg fill="none" height="24" viewBox="0 0 24 24" width="24"
                                           xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M6 19C6 19.5304 6.21071 20.0391 6.58579 20.4142C6.96086 20.7893 7.46957 21 8 21H16C16.5304 21 17.0391 20.7893 17.4142 20.4142C17.7893 20.0391 18 19.5304 18 19V7H6V19ZM8 9H16V19H8V9ZM15.5 4L14.5 3H9.5L8.5 4H5V6H19V4H15.5Z"
                                            fill="#0033A0"/>
                                      </svg>
                                    </div>
                                  </div>
                                </div>
                              </v-expansion-panel-header>

                              <v-expansion-panel-content class="level3">
                                <div class="list-item-wrap">
                                  <v-text-field v-model="subQuestion.ifOption"
                                                label="Условие-ответ на родительский вопрос"></v-text-field>
                                  <v-text-field v-model="subQuestion.description"
                                                label="Пояснения к вопросу"></v-text-field>

                                  <v-checkbox v-model="subQuestion.multiple"
                                              label="Допускается несколько вариантов ответов"></v-checkbox>

                                  <v-checkbox v-model="subQuestion.typeTenScore"
                                              label="Скоринг (значения в ряд)"></v-checkbox>
                                  <v-checkbox v-model="subQuestion.typeText"
                                              label="Текстовое поле (свободный ответ)"></v-checkbox>

                                  <table class="config-table">
                                    <thead>
                                    <tr>
                                      <th colspan="2">Варианты ответов</th>
                                      <th colspan="2">Баллы за ответ</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="(option, optionIndex) in subQuestion.options"
                                        :key="'sadf'+stepIndex+'.'+questionIndex+'.'+subQuestionIndex+'.'+optionIndex">
                                      <td>{{ optionIndex + 1 }}.</td>
                                      <td>
                                        <v-text-field v-model="option.text"></v-text-field>
                                      </td>
                                      <td>
                                        <v-text-field v-model="option.score"></v-text-field>
                                      </td>
                                      <td>
                                        <div v-if="subQuestion.options.length>2" class="delete"
                                             @click="subQuestion.options.splice(optionIndex,1), $forceUpdate()">
                                          <svg fill="none" height="24" viewBox="0 0 24 24" width="24"
                                               xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M6 19C6 19.5304 6.21071 20.0391 6.58579 20.4142C6.96086 20.7893 7.46957 21 8 21H16C16.5304 21 17.0391 20.7893 17.4142 20.4142C17.7893 20.0391 18 19.5304 18 19V7H6V19ZM8 9H16V19H8V9ZM15.5 4L14.5 3H9.5L8.5 4H5V6H19V4H15.5Z"
                                                fill="#0033A0"/>
                                          </svg>
                                        </div>
                                      </td>
                                    </tr>
                                    </tbody>
                                  </table>
                                  <a class="configAdd" href="#" @click.prevent="addOption(subQuestion)">+ Добавить вариант
                                    ответа</a>
                                </div>

                              </v-expansion-panel-content>

                            </v-expansion-panel>
                            <a class="configAdd" href="#" @click.prevent="addSubQuestion(question)">+ Добавить
                              подвопрос</a>
                          </v-expansion-panels>


                        </v-expansion-panel-content>

                        <a v-if="questionIndex===step.questions.length-1" class="configAdd"
                           href="#" @click.prevent="addQuestion(step)">+ Добавить вопрос</a>

                      </v-expansion-panel>


                    </v-expansion-panels>


                    <p class="mt-24">Справочник результатов для раздела анкеты</p>

                    <table class="config-table">
                      <thead>
                      <tr>
                        <th colspan="2">Результат</th>
                        <th colspan="2">Порог баллов (влючительно)</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(result, resultIndex) in step.stepResults"
                          :key="'adfweir0g'+stepIndex+'.'+resultIndex">
                        <td>{{ resultIndex + 1 }}.</td>
                        <td>
                          <v-text-field v-model="result.name"></v-text-field>
                        </td>
                        <td>
                          <v-text-field v-model="result.threshold"></v-text-field>
                        </td>
                        <td>
                          <div v-if="step.stepResults.length > 2" class="delete"
                               @click="step.stepResults.splice(resultIndex, 1), $forceUpdate()">
                            <svg fill="none" height="24" viewBox="0 0 24 24" width="24"
                                 xmlns="http://www.w3.org/2000/svg">
                              <path
                                  d="M6 19C6 19.5304 6.21071 20.0391 6.58579 20.4142C6.96086 20.7893 7.46957 21 8 21H16C16.5304 21 17.0391 20.7893 17.4142 20.4142C17.7893 20.0391 18 19.5304 18 19V7H6V19ZM8 9H16V19H8V9ZM15.5 4L14.5 3H9.5L8.5 4H5V6H19V4H15.5Z"
                                  fill="#0033A0"/>
                            </svg>
                          </div>
                        </td>
                      </tr>

                      </tbody>
                    </table>
                    <a class="configAdd" href="#" @click.prevent="addStepResult(step)">+ Добавить результат</a>


                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>

            </div>


            <div class="list-item-wrap">
              <a v-if="config.anket.steps.length < 3" class="configAdd" href="#" @click.prevent="addStep()">+ Добавить
                раздел
                анкеты</a>
            </div>


          </div>


          <h2 class="mt-48">Общий справочник<br> результатов анкетирования</h2>

          <table v-if="config.anket.steps.length === 1" class="ankets-config-table">
            <template v-for="(result0, result0Index) in config.anket.steps[0].stepResults">
              <tr :key="'asdfasfdtr'+result0Index">
                <th v-for="(step, stepIndex) in config.anket.steps" :key="'saf'+stepIndex">Раздел {{ stepIndex + 1 }}
                </th>
                <th>Код</th>
                <th>Результат</th>
              </tr>
              <tr :key="'asdfasfdtr2'+result0Index">
                <td>{{ result0.name }}</td>
                <td>
                  <v-text-field v-model="config.anket.results[result0Index].code"></v-text-field>
                </td>
                <td>
                  <v-textarea v-model="config.anket.results[result0Index].name"></v-textarea>
                  <label>Расшифровка</label>
                  <v-textarea v-model="config.anket.results[result0Index].description"></v-textarea>
                </td>
              </tr>
            </template>
          </table>

          <table v-if="config.anket.steps.length === 2" class="ankets-config-table">
            <template v-for="(result0, result0Index) in config.anket.steps[0].stepResults">
              <template v-for="(result1, result1Index) in config.anket.steps[1].stepResults">
                <tr :key="'asdfasfdtr12'+result0Index+'.'+result1Index">
                  <th v-for="(step, stepIndex) in config.anket.steps" :key="'saf'+stepIndex">Раздел {{ stepIndex + 1 }}
                  </th>
                  <th>Код</th>
                  <th>Результат</th>
                </tr>
                <tr :key="'asdfasfdtr13'+result0Index+'.'+result1Index">
                  <td>{{ result0.name }}</td>
                  <td>{{ result1.name }}</td>
                  <td>
                    <v-text-field v-model="config.anket.results[result0Index][result1Index].code"></v-text-field>
                  </td>
                  <td>
                    <v-textarea v-model="config.anket.results[result0Index][result1Index].name"></v-textarea>
                    <label>Расшифровка</label>
                    <v-textarea v-model="config.anket.results[result0Index][result1Index].description"></v-textarea>
                  </td>
                </tr>
              </template>
            </template>
          </table>


          <table v-if="config.anket.steps.length === 3" class="ankets-config-table">
            <template v-for="(result0, result0Index) in config.anket.steps[0].stepResults">
              <template v-for="(result1, result1Index) in config.anket.steps[1].stepResults">
                <template v-for="(result2, result2Index) in config.anket.steps[2].stepResults">

                  <tr :key="'asdfasfdtr123'+result0Index+'.'+result1Index+'.'+result2Index">
                    <th v-for="(step, stepIndex) in config.anket.steps" :key="'saf'+stepIndex">Раздел {{
                        stepIndex + 1
                      }}
                    </th>
                    <th>Код</th>
                    <th>Результат</th>
                  </tr>
                  <tr :key="'asdfasfdtr133'+result0Index+'.'+result1Index+'.'+result2Index">
                    <td>{{ result0.name }}</td>
                    <td>{{ result1.name }}</td>
                    <td>{{ result2.name }}</td>

                    <td>
                      <v-text-field
                          v-model="config.anket.results[result0Index][result1Index][result2Index].code"></v-text-field>
                    </td>
                    <td>
                      <v-textarea
                          v-model="config.anket.results[result0Index][result1Index][result2Index].name"></v-textarea>
                      <label>Расшифровка</label>
                      <v-textarea
                          v-model="config.anket.results[result0Index][result1Index][result2Index].description"></v-textarea>
                    </td>
                  </tr>
                </template>
              </template>
            </template>
          </table>

          <div class="save-buttons mt-24">
            <v-btn color="primary" outlined @click="$router.push('/config/ankets')">Отмена</v-btn>
            <v-btn :disabled="submitButtonDisabled" color="primary" @click="submit">Сохранить</v-btn>
          </div>


        </div>
        <div v-if="errorText!=null" class="error" style="position: fixed;bottom: 0">
          Ошибка: {{ errorText }}
        </div>
      </div>
      <div v-else style="height:1000px"></div>
    </transition>
  </div>
</template>

<style lang="scss">
@import "../../styles/main.scss";
</style>

<style lang="scss" scoped>
</style>

<script>

export default {
  name: "AnketsConfigEdit",
  props: {},
  components: {},
  data() {
    return {
      config: {
        id: null,

        anket: {
          title: "",
          subTitle: "",
          description: "",
          subDescription: "",
          anonymous: false,
          noShowResult: false,
          steps: [{
            name: "",
            questions: [
              {
                text: "",
                subText: "",
                description: "",
                partitionBreak: "",
                multiple: false,
                typeTenScore: false,
                typeText: false,
                options: [
                  {
                    text: "",
                    score: 0
                  },
                  {
                    text: "",
                    score: 0
                  }
                ],
                subQuestions: []
              }
            ],
            stepResults: [
              {
                name: "",
                threshold: 0
              },
              {
                name: "",
                threshold: 0
              }
            ]
          }],
          results: [
            {code: "", name: "", description: ""},
            {code: "", name: "", description: ""}
          ]
        }
      },

      errorText: null,
      submitButtonDisabled: false,
      apiLoaded: false
    };
  },
  filters: {
    dateFormat: function (date) {
      if (date == null) {
        return ''
      }
      let mdate = new Date(date)
      let options = {timeZone: 'Europe/Moscow', year: 'numeric', month: 'numeric', day: 'numeric'}
      return mdate.toLocaleDateString('ru-RU', options)
    }
  },
  methods: {
    deleteStep(stepIndex) {
      this.config.anket.steps.splice(stepIndex, 1)
      this.updateAnketResultStructure()
      this.$forceUpdate()
    },

    addStep() {
      this.config.anket.steps.push({
        name: "",
        questions: [
          {
            text: "",
            subText: "",
            description: "",
            partitionBreak: "",
            multiple: false,
            typeTenScore: false,
            typeText: false,
            options: [
              {
                text: "",
                score: null
              },
              {
                text: "",
                score: null
              }
            ],
            subQuestions: []
          },
        ],
        stepResults: [
          {
            name: "",
            threshold: null
          },
          {
            name: "",
            threshold: null
          }
        ]
      })
      this.updateAnketResultStructure()
      this.$forceUpdate()
    },

    updateAnketResultStructure() {
      if (this.config.anket.steps.length === 1) {
        this.config.anket.results = []
        for (let i = 0; i < this.config.anket.steps[0].stepResults.length; i++) {
          this.config.anket.results.push({code: "", name: "", description: ""})
        }
      }
      if (this.config.anket.steps.length === 2) {
        this.config.anket.results = []
        for (let i = 0; i < this.config.anket.steps[0].stepResults.length; i++) {
          this.config.anket.results[i] = []
          for (let j = 0; j < this.config.anket.steps[1].stepResults.length; j++) {
            this.config.anket.results[i].push({code: "", name: "", description: ""})
          }
        }
      }
      if (this.config.anket.steps.length === 3) {
        this.config.anket.results = []
        for (let i = 0; i < this.config.anket.steps[0].stepResults.length; i++) {
          this.config.anket.results.push([])
          for (let j = 0; j < this.config.anket.steps[1].stepResults.length; j++) {
            this.config.anket.results[i].push([])
            for (let k = 0; k < this.config.anket.steps[2].stepResults.length; k++) {
              this.config.anket.results[i][j].push({code: "", name: "", description: ""})
            }
          }
        }
      }
    },

    addQuestion(step) {
      step.questions.push({
        text: "",
        subText: "",
        description: "",
        partitionBreak: "",
        multiple: false,
        typeTenScore: false,
        typeText: false,
        options: [
          {
            text: "",
            score: null
          },
          {
            text: "",
            score: null
          }
        ],
        subQuestions: []
      })
      this.$forceUpdate()
    },

    addSubQuestion(question) {
      // console.log(question)
      if (question.subQuestions == null)
        question.subQuestions = []
      question.subQuestions.push({
        text: "",
        description: "",
        ifOption: "",
        multiple: false,
        typeTenScore: false,
        typeText: false,
        options: [
          {
            text: "",
            score: null
          },
          {
            text: "",
            score: null
          }
        ]
      })
      this.$forceUpdate()
    },

    addOption(question) {
      question.options.push({
        text: "",
        score: null
      })
      this.$forceUpdate()
    },

    addStepResult(step) {
      step.stepResults.push({
        name: "",
        threshold: null
      })
      this.updateAnketResultStructure()
      this.$forceUpdate()
    },

    questionUp(step, questionIndex){
      if(questionIndex === 0)
        return
      let tmp = step.questions[questionIndex]
      step.questions[questionIndex] = step.questions[questionIndex-1]
      step.questions[questionIndex-1] = tmp
      this.$forceUpdate()
    },

    questionDown(step, questionIndex){
      if(questionIndex === step.questions.length-1)
        return
      let tmp = step.questions[questionIndex]
      step.questions[questionIndex] = step.questions[questionIndex+1]
      step.questions[questionIndex+1] = tmp
      this.$forceUpdate()
    },

    async submit() {
      this.config.anketJson = JSON.stringify(this.config.anket)
      this.submitButtonDisabled = true
      let req = await this.$postApi("/ankets/saveConfig", this.config)
      if (req.ok) {
        this.$router.push("/config/ankets")
      } else {
        this.errorText = "Ошибка подключения к серверу или обработки запроса: " + req.error
        this.submitButtonDisabled = false
      }
    },

    loadJson() {
      let file = this.$refs['fileInput'].files[0];
      let reader = new FileReader();
      reader.readAsText(file);
      reader.componentThis = this
      reader.onload = function () {
        let anket

        try {
          anket = JSON.parse(reader.result)
        } catch (e) {
          reader.componentThis.errorText = "Файл должен содержать конфигурацию в формате JSON"
          return
        }
        // console.log(anket)
        reader.componentThis.config.anket = anket
      }

      reader.onerror = function () {
        alert('Ошибка загрузки файла')
      };
    },

    noShowResultToggle(anonymous) {
      if (anonymous) {
        this.config.anket.noShowResult = true;
      }
    },
  },
  async beforeMount() {
    if (this.$route.params.id != null) {
      //режим редактирования
      let req = await this.$getApi("/ankets/getConfig", {id: this.$route.params.id})
      if (req.ok) {
        this.config = req.payload
        this.config.anket = JSON.parse(this.config.anketJson)
        // console.log('this.config', this.config)
        this.apiLoaded = true
      } else {
        this.errorText = "Ошибка подключения к серверу или обработки запроса: " + req.error
      }
    } else {
      this.apiLoaded = true
    }
  }
};
</script>
