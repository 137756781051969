import { render, staticRenderFns } from "./AnketsConfigEdit.vue?vue&type=template&id=5c269766&scoped=true&"
import script from "./AnketsConfigEdit.vue?vue&type=script&lang=js&"
export * from "./AnketsConfigEdit.vue?vue&type=script&lang=js&"
import style0 from "./AnketsConfigEdit.vue?vue&type=style&index=0&id=5c269766&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c269766",
  null
  
)

export default component.exports